import { template as template_86d6d261e3e14cabbc62575d7d317ce5 } from "@ember/template-compiler";
const FKLabel = template_86d6d261e3e14cabbc62575d7d317ce5(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
